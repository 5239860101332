"use client";

import { useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  HandThumbUpIcon,
  LightBulbIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { GiBookshelf } from "react-icons/gi";
import { ImBullhorn } from "react-icons/im";

import EmbeddedCheckoutModal from "~/components/EmbeddedCheckoutModal";

const includedFeatures = [
  "A merchandizing display & marketing materials",
  "The latest 2 volumes of The Rialto Books Review",
  "33% equity in all purchases",
  "All future book releases",
];

const features = [
  {
    name: "Let Biblish Market You",
    description:
      "Biblish has an interest in meeting readers where they are in order to sell more books. A purchase of The Biblish Library will mean we will market your shop as though it is our own.",
    icon: ImBullhorn,
  },
  {
    name: "It's easy",
    description:
      "There is no need to keep track of inventory. New books for the Library will be mailed to your shop in a Biblish mailer. Just open the envelope, take out the book, and place the book in the display.",
    icon: HandThumbUpIcon,
  },
  {
    name: "Turn unused space into a unique asset",
    description:
      'Outside of the space dedicated to your cafe equipment, the 5" x 14" inch area where you display The Biblish Library will become the most valuable space in your coffee shop.',
    icon: LightBulbIcon,
  },
  {
    name: "Become a part of Biblish's mission",
    description:
      "To every extent within our power, we want each coffeeshop that hosts a Bilbish Library to become a place for local writers to think, discuss, work, and find an outlet for their work, and we are interested in learning along with you how best to make that ambition a reality.",
    icon: GiBookshelf,
  },
];

export default function Page() {
  const featuresRef = useRef<HTMLDivElement>(null);

  return (
    <div className="bg-white">
      <main className="isolate">
        {/* Hero section */}

        <div className="relative pt-14">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#0d9488] to-[#d6e2e0] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
          </div>
          <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Biblish Library
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  The Biblish Library is a simple product that coffee shops can
                  purchase to begin offering books by small publishers in a
                  painless manner. Derive an equity from each purchase, never
                  worry about inventory or order fulfillment, and present your
                  customers with one more reason to visit your shop again and
                  again.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <EmbeddedCheckoutModal
                    text="Get Started"
                    className="rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  />

                  <button
                    onClick={() =>
                      featuresRef.current?.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </button>
                </div>
              </div>
              <div className="mt-16 flow-root sm:mt-24">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <Image
                    alt="App screenshot"
                    src="/images/about/3_cropped.jpg"
                    width={2432}
                    height={1442}
                    className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#0d9488] to-[#0d9478]  opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
        </div>

        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          {/* Logo cloud */}
          {/* <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mt-16 flex justify-center">
              <p className="relative rounded-full px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
                <span className="hidden md:inline">
                  Transistor saves up to $40,000 per year, per employee by
                  working with us.
                </span>
                <a href="#" className="font-semibold text-teal-600">
                  <span aria-hidden="true" className="absolute inset-0" /> Watch
                  our video <span aria-hidden="true">&rarr;</span>
                </a>
              </p>
            </div>
          </div> */}
        </motion.div>
        <div ref={featuresRef} className="lg:mt-32"></div>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          {/* Feature section */}
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-teal-600">
                A simple product
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                All the advantages of becoming a bookseller with none of the
                work
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Bring books into your coffee shop for customers to read,
                discuss, and purchase, while you continue to focus on what you
                do best, running your coffee shop
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-teal-600">
                        <feature.icon
                          aria-hidden="true"
                          className="h-6 w-6 text-white"
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </motion.div>

        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          {" "}
          {/* Testimonial section */}
          <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
            <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
              <figcaption className="mt-10">
                <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                  <div className="font-semibold text-gray-900">
                    We Have a Lot in Common
                  </div>
                </div>
              </figcaption>
              <figure className="mt-10">
                <p className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  Like you, we are a small, independent entity that is hoping to
                  have an outsized impact on our community. Biblish is trying to
                  create a horticultural, rather than an agricultural, model for
                  book distribution. Big bookstores do not seem to have an
                  interest in the earnest and craftsmanly writing we specialize
                  in. So, we thought we would try to meet potential readers
                  where they already are, and where they may be receptive to
                  something new. That&apos;s where your lovingly curated coffee
                  shop comes in.
                </p>
              </figure>
            </div>
          </section>
        </motion.div>

        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          {/* Pricing section */}
          <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Are you ready?
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Purchase your subscription to The Biblish Library and
                  everything you need to get started will be shipped to your
                  store right away
                </p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                <div className="p-8 sm:p-10 lg:flex-auto">
                  <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                    The Biblish Library
                  </h3>
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    The Biblish library is Biblish&apos;s book stocking program
                    for coffee shops
                  </p>
                  <div className="mt-10 flex items-center gap-x-4">
                    <h4 className="flex-none text-sm font-semibold leading-6 text-teal-600">
                      What&apos;s included
                    </h4>
                    <div className="h-px flex-auto bg-gray-100" />
                  </div>
                  <ul
                    role="list"
                    className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                  >
                    {includedFeatures.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          aria-hidden="true"
                          className="h-6 w-5 flex-none text-teal-600"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                    <div className="mx-auto max-w-xs px-8">
                      <div className="flex flex-col items-center gap-3">
                        <p className="mt-6 flex w-full items-baseline justify-center gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            $25
                          </span>
                          <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                            /one-time
                          </span>
                        </p>
                        <PlusIcon className="size-5" />
                        <p className="flex w-full items-baseline justify-center gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            $12
                          </span>
                          <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                            /year
                          </span>
                        </p>
                      </div>

                      <EmbeddedCheckoutModal
                        className="mt-5 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        text="Get Access"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          {/* FAQs */}
          {/* <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <div
                  key={faq.id}
                  className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
                >
                  <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-4 lg:col-span-7 lg:mt-0">
                    <p className="text-base leading-7 text-gray-600">
                      {faq.answer}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div> */}

          <div>
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Not quite sure yet?
                <br />
                Request your free trial here.
              </h2>
              <div className="mt-10 flex items-center gap-x-6 lg:mr-32 lg:mt-0 lg:flex-shrink-0">
                <Link
                  href="trial"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Request Trial <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </main>
    </div>
  );
}
